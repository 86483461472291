<template>
    <div class='typePage'>
      <div class="topSec">
        <img src="../../assets/avatar.jpeg" />
        <div class="infoBox">
          <div class="topLine">
            <span>{{memberInfo.name ||'暂无姓名'}}</span>
            <Tag v-if="memberInfo.member==1" class="hasBg" color="#ffffff">{{memberInfo.memberTypeName}}</Tag>
            <Tag v-else   color="#ffffff" plain>非会员</Tag>
          </div>
          <div class="topLine bottomLine">
            <span>{{memberInfo.mobile|| "-"}}</span>
            <span v-if="memberInfo.member==1" class="light">{{memberInfo.season?memberInfo.season+'年':"-"}}</span>
          </div>
        </div>
      </div>
       <div v-if="list.length>0">
           <Cell v-for="item in list" :key="item.id" :title="item.memberName" is-link value="缴费"  @click="pay(item)"/>
       </div>
       <action-sheet
            v-model="show"
            :actions="actions"
            title='请选择缴费方式'
            description="立即缴费为微信缴费，线上汇款需提供汇款成功截图"
            cancel-text="取消"
            close-on-click-action
            @cancel="onCancel"
            @select='selectPay'
            />
    </div>
</template>

<script>
  import { Tag, Icon } from "vant";
  import { getMemberInfo } from "@/api/member.js";
import {Cell,ActionSheet } from "vant";
import {getTypeList} from "@/api/member.js"

    export default {
        name:'memberType',
        components:{
            Cell,
            ActionSheet,
          Tag,
          Icon,
        },
        data(){
            return{
                id:'',//会员id
                memberType:'',//会员类型
                list:[],
                show:false,
                actions:[{name:'立即缴费'},{name:'线上汇款'}],
                memberInfo:{},

            }
        },
        created(){
            this.getTypeList();
            let {id,memberType}=this.$route.query;
            this.id=id;
            this.memberType=memberType;

          let clientId = localStorage.getItem("clientId");
          this.getMember(clientId);
        },
        methods:{
            getTypeList(){
                getTypeList().then(res=>{
                    if(res.status=='S'){
                      console.log(res.data)
                        this.list=res.data;
                    }
                })
            },
            pay(item){
                this.item=item
                this.show=true
            },
            onCancel(){
                this.show=false;
            },
            selectPay(val){
                let payType=val.name=='立即缴费'?'pay':'remit';
                let data=Object.assign({},this.item,{memberId:this.id,memberType:this.memberType,payType})
                this.$router.push("/memberPayFee?infos="+JSON.stringify(data))
            },
          getMember(id) {
            getMemberInfo({id}).then(res=>{
              if(res.status=="S"){
                this.memberInfo=res.data
                localStorage.setItem("mobile",res.data.mobile)
              }
            })
          },
        },
    }
</script>

<style lang="less" scoped>
::v-deep.van-cell{
    padding:0.25rem 0.3rem;
}
.typePage{
    background: #f5f5f5;
    width: 100vw;
    height: 100vh;
    /*padding-top: 0.1rem;*/
    box-sizing: border-box;
}
::v-deep.van-tag {
  padding: 0.05rem 0.1rem;
  border-radius: 3px;
  font-size: 12px;
  font-weight: normal;
  &.hasBg {
    background: url("./../../assets/btnBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #2b72ff;
    text-shadow: 0px 0px 4px rgba(0, 0, 255, 0.3);
  }
}

::v-deep.van-tag--plain {
  background: transparent;
}
.topSec {
  height: 1.6rem;
  background: url("../../assets/loginBg.png");
  background-size: 100% 120%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.3rem;
  img {
    flex: 0 0 0.6rem;
    height: 0.6rem;
    border-radius: 0.3rem;
    margin-right: 0.15rem;
  }
  .infoBox {
    flex: 1;
    height: 0.6rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    color: #fff;
    .topLine {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
    }
    .bottomLine {
      font-size: 12px;
      font-weight: normal;
      .light {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
</style>
